@import "opensans.css";
@import "roboto.css";
@import "lato.css";

@import "statusbar.css";

@tailwind base;
@tailwind components;
@tailwind utilities;

body {
    @apply text-sm;
    font-family: 'Open Sans', sans-serif;
}

.content .text-orange {
    color: #ec7e11;
}

.content .text-green {
    color: #aeb847;
}

.container {
    max-width: 1170px;
}

.navbar {
    height: 66px;
    line-height: 66px;
    border-bottom: 1px solid #dadada;
}

.slider {
    height: 250px;
    width: 100%;
    background-position: top;
    background-repeat: no-repeat;
    background-size: cover;
}
.slider-de, .slider-it { background-image: url("/images/slide_de_sm.jpg"); }
@media (min-width: 640px) {
    .slider-de, .slider-it { background-image: url("/images/slide_de_md.jpg"); }
}
@media (min-width: 768px) {
    .slider-de, .slider-it { background-image: url("/images/slide_de_lg.jpg"); }
}
@media (min-width: 1024px) {
    .slider-de, .slider-it { background-image: url("/images/slide_de.jpg"); }
}

.slider-ch { background-image: url("/images/slide_ch_sm.jpg"); }
@media (min-width: 640px) {
    .slider-ch { background-image: url("/images/slide_ch_md.jpg"); }
}
@media (min-width: 768px) {
    .slider-ch { background-image: url("/images/slide_ch_lg.jpg"); }
}
@media (min-width: 1024px) {
    .slider-ch { background-image: url("/images/slide_ch.jpg"); }
}

.slider-at { background-image: url("/images/slide_at_sm.jpg"); }
@media (min-width: 640px) {
    .slider-at { background-image: url("/images/slide_at_md.jpg"); }
}
@media (min-width: 768px) {
    .slider-at { background-image: url("/images/slide_at_lg.jpg"); }
}
@media (min-width: 1024px) {
    .slider-at { background-image: url("/images/slide_at.jpg"); }
}


.caret {
    display: inline-block;
    width: 0;
    height: 0;
    margin-left: 2px;
    vertical-align: middle;
    border-top: 4px solid;
    border-right: 4px solid transparent;
    border-left: 4px solid transparent;
}

.dropdown-menu {
    @apply text-black bg-white rounded border-black;
    line-height: normal;
    top: 70px;
    right: 0;
    position: absolute;
    min-width: 120px;
}
.dropdown-menu hr {
    border-color: black;
    width: 100%;
}
.dropdown-menu ul {
    list-style: none;
    margin: 0;
    padding: 0;
    font-weight: normal;
}
.dropdown-menu ul li {
    @apply px-3 py-2;
}
.dropdown-menu a {
    @apply text-black;
    font-weight: 400;
    text-decoration: none;
}

.nav-item {
    @apply flex-1 text-center text-sm;
    border-left: 1px solid #ccc;
    padding: 15px;
}
.nav-item:last-child {
    border-right: 1px solid #ccc;
}
.nav-item a {
    @apply text-gray-dark;
    text-transform: uppercase;
    font-weight: bold;
}
header {
    @apply border-b-2 border-orange;
}

.has-errors {
    @apply text-sm text-red;
}

.content {
    @apply py-6;
}

.content p {
    @apply py-2;
}

.content h1 {
    @apply text-gray-dark text-4xl;
}
.content h1.underlined {
    @apply border-gray-lighter border-b;
    padding-top: 10px;
    padding-bottom: 10px;
    margin: 0 0 25px;
}
.content h2 {
    @apply text-gray-dark text-2xl;
}
.content h3 {
    @apply text-gray-dark text-xl;
}
.content h4, .content h5, .content h6 {
    @apply text-gray-dark text-base;
}
.form {
    @apply bg-gray-lighter grid gap-4 p-4;
}
.form input {
    @apply w-full;
    background-color: #fff;
    background-image: none;
    border: 1px solid #ccc;
    box-shadow: 0 1px 1px rgba(0,0,0,.075) inset;
    color: #555;
    display: block;
    font-size: 14px;
    height: 34px;
    line-height: 1.42857;
    padding: 6px 12px;
    transition: border-color .15s ease-in-out 0s,box-shadow .15s ease-in-out 0s;
}
.form input[type="checkbox"] {
    display: inline-block;
    height: auto;
    padding: 0;
    width: auto;
    line-height: normal;
}
.form textarea {
    @apply w-full;
    background-color: #fff;
    background-image: none;
    border: 1px solid #ccc;
    box-shadow: 0 1px 1px rgba(0,0,0,.075) inset;
    color: #555;
    display: block;
    font-size: 14px;
    height: 102px;
    line-height: 1.42857;
    padding: 6px 12px;
    transition: border-color .15s ease-in-out 0s,box-shadow .15s ease-in-out 0s;
}
.form input[required], .form textarea[required] {
    border-color: #aeb847;
    border-style: solid;
    border-width: 1px 1px 1px 3px;
}
.form input.form-error {
    @apply border-red border-l-2;
}
.form #privacy {
    @apply pr-2;
}
.form a {
    @apply underline;
}
.form button.btn {
    @apply flex;
}
.form button.btn:hover {
    @apply shadow-lg;
}

.form button.btn svg {
    @apply w-5 h-5 pr-2;
}
.form button.btn-orange {
    @apply bg-orange border border-orange rounded px-3 py-2;
}

.formBedrijfsnaam { grid-area: formBedrijfsnaam; }
.formContactpersoon { grid-area: formContactpersoon; }
.formTelefoon { grid-area: formTelefoon; }
.formKlantnummer { grid-area: formKlantnummer; }
.formEmail { grid-area: formEmail; }
.formBericht { grid-area: formBericht; }
.formPrivacy { grid-area: formPrivacy; }
.formButton { grid-area: formButton; }

.form-contact-w2d {
    grid-template-columns: 1fr 1fr;
    grid-template-areas:
        "formBedrijfsnaam formContactpersoon"
        "formTelefoon formKlantnummer"
        "formEmail formBericht"
        "formPrivacy formPrivacy"
        "notify notify"
        "formButton formButton"
    ;
}

.advBedrijfsnaam { grid-area: advBedrijfsnaam; }
.advContactpersoon { grid-area: advContactpersoon; }
.advAdres { grid-area: advAdres; }
.advPostcode { grid-area: advPostcode; }
.advPlaats { grid-area: advPlaats; }
.advTelefoon { grid-area: advTelefoon; }
.advFax { grid-area: advFax; }
.advWebsite { grid-area: advWebsite; }
.advEmail { grid-area: advEmail; }
.advTrefwoord { grid-area: advTrefwoord; }
.advOmschrijving { grid-area: advOmschrijving; }
.advPrivacy { grid-area: advPrivacy; }
.advButton { grid-area: advButton; }
.notify { grid-area: notify; }


.form-adverteren-w2d {
    grid-template-columns: 1fr 1fr;
    grid-template-areas:
        "advBedrijfsnaam advContactpersoon"
        "advAdres ."
        "advPostcode advPlaats"
        "advTelefoon advFax"
        "advWebsite advEmail"
        "advTrefwoord advOmschrijving"
        "advPrivacy advPrivacy"
        "notify notify"
        "advButton advButton"
;
}
@media (max-width: 640px) {
    .form-adverteren-w2d {
        grid-template-columns: 1fr;
        grid-template-areas: unset;
    }
    .form-contact-w2d {
        grid-template-columns: 1fr;
        grid-template-areas: unset;
    }

    .advBedrijfsnaam { grid-area: unset; }
    .advContactpersoon { grid-area: unset; }
    .advAdres { grid-area: unset; }
    .advPostcode { grid-area: unset; }
    .advPlaats { grid-area: unset; }
    .advTelefoon { grid-area: unset; }
    .advFax { grid-area: unset; }
    .advWebsite { grid-area: unset; }
    .advEmail { grid-area: unset; }
    .advTrefwoord { grid-area: unset; }
    .advOmschrijving { grid-area: unset; }
    .advPrivacy { grid-area: unset; }
    .advButton { grid-area: unset; }

    .formBedrijfsnaam { grid-area: unset; }
    .formContactpersoon { grid-area: unset; }
    .formTelefoon { grid-area: unset; }
    .formKlantnummer { grid-area: unset; }
    .formEmail { grid-area: unset; }
    .formBericht { grid-area: unset; }
    .formPrivacy { grid-area: unset; }
    .formButton { grid-area: unset; }
    .notify { grid-area: unset; }
}

.company-quicklinks {
    @apply text-gray-light;
}
.company-quicklinks svg {
    height: 36px;
    width: 36px;
}
.company-quicklinks a {
    @apply text-gray-darker;
}
.company-quicklinks a:hover {
    @apply text-black;
}
.company-quicklinks div {
    @apply pb-4;
}
.company-quicklinks .maps_link:hover {
    @apply text-maps cursor-pointer;
}
.company-logo {
    width: 160px;
}
.company-logo img {
    max-width: 140px;
    height: auto;
}

.googlemaps {
    @apply flex items-center justify-center bg-gray-lighter font-semibold;
    border-color: #aeb847 #aeb847 #ec7e11 #ec7e11;
    border-style: solid;
    border-width: 2px;
    height: 450px;
    width: 100%;
}
.googlemaps a {
    white-space: nowrap;
    text-decoration: underline;
}
.googlemaps #map {
    @apply w-full h-full;
}
.googlemaps .no-map {
    @apply p-4;
}
#map .no-map a {
    @apply underline;
}
.googlemaps h1 {
    @apply font-bold text-lg;
}
.hideGoogleMaps {
    @apply text-gray-dark;
}

.listing {
    @apply grid;
    grid-template-columns: 25% 50% 25%;
}
.listing > div {
}

.linewrapper {
    width: 100%;
    position: relative;
    margin-bottom: 20px;
    margin-top: 10px;
    padding-bottom: 5px;
}
.line {
    position: absolute;
    background: #3a3c3c;
    height: 1px;
    width: 100%;
    top: 10px;
    z-index: 1;
}
.wordwrapper {
    @apply bg-gray absolute;
    z-index: 2;
    right: 10px;
    padding: 0 3px;
    font-weight: 500;
    color: #3a3c3c;
    font-family: Roboto, sans-serif;
}

.listing-table {
    @apply flex py-1;
}
.listing-key {
    @apply font-bold w-1/3;
    color: #3a3c3c;
    font-family: 'Lato', sans-serif;
}
.listing-value {
    @apply w-2/3;
}
.listing-value a {
    @apply block truncate;
}
.listing-maps {
    /*height: 200px;*/
}
.listing-maps #map {
    /*height: 200px;*/
}
.listing-side {
    @apply bg-gray p-4;
}
.listing-content {
    @apply p-4 text-gray-dark;
}

form[name="contact_sidebar"] {
    @apply py-2;
}
.listing-sidebar-field {
    @apply py-1;
}
.listing-sidebar-field input[type="text"], .listing-sidebar-field input[type="email"], .listing-sidebar-field textarea {
    @apply w-full py-1 px-2;
}
.listing-sidebar-field label input[type="checkbox"] {
    @apply mr-1;
}
.listing-sidebar-field button {
    @apply w-full bg-green rounded py-1;
}
.listing-sidebar-field button:hover {
    @apply shadow-lg;
}

.listing-content form[name="contact_sidebar"] {
    padding-top: 0;
}
.listing-content .listing-sidebar-field input[type="text"],
.listing-content .listing-sidebar-field input[type="email"],
.listing-content .listing-sidebar-field textarea {
     @apply border border-gray-light;
 }
.listing-content h1 {
    line-height: 40px;
    font-weight: 600;
}
.listing-content h2,
.listing-content h3,
.listing-content h4,
.listing-content h5,
.listing-content h6
{
    @apply py-2;
}
.listing-content h2,
.listing-content h3
{
    @apply font-bold;
}

.pagination {
    @apply flex;
}
.pagination li {
    @apply flex w-8 h-8 border border-gray-light border-r-0 items-center justify-center;
}
.pagination li:first-child {
    @apply rounded-l;
}
.pagination li:last-child {
    @apply border-r rounded-r;
}
.pagination li.active {
    @apply bg-green font-bold text-white border-green;
}
@media (max-width: 1024px) {
    .pagination {
        @apply m-4;
    }
}
.nav-mobile {
    @apply p-2;
}
.listing-contact {
    @apply hidden;
}
.listing-contact-button {
    @apply hidden border-2 border-white mx-4 mt-0 mb-4 p-2 text-lg font-bold text-white uppercase shadow-md rounded-md items-center justify-center bg-green;
}
@media (max-width: 768px) {
    .listing {
        @apply block;
    }
    .listing-contact-button {
        @apply flex;
    }
}
.listing-content ul {
    @apply pl-3;
    list-style: disc;
}
.listing-content a {
    @apply underline;
}

.flashmessage {
    @apply text-white font-bold leading-relaxed px-2;
}
.notify_success {
    @apply bg-green;
}
.notify_failure {
    @apply bg-orange;
}
.categories_column {
    @apply w-1/3 leading-loose;
}
@media (max-width: 768px) {
    .categories_column {
        @apply w-full py-2;
    }
}

.company-whatsapp { max-width: 260px;}
